@import "../../assets/styles/colors";
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  //background: #354052;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
  background-color: $primary;
  background-image: linear-gradient(180deg ,$primary 10%,#224abe 100%);
  background-size: cover;
  hr {
     border-top: 1px solid rgba(255, 255, 255, 0.1);
     height: 1px;
     width: 90%;
  }
  a {
      color: #ccc;
  }
  a:hover {
      color: #fff;
  }
  .nav .nav-item .active{
      color: #fff;
  }
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  //background: #313b4c;
  color: #fff;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: $primary;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: $primary;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  //background: #bbb;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
}
