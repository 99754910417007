.modal-content-loading {
    width: 99%;
    height: 100%;
    position: absolute;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}
