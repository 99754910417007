.date {
  opacity: 0.5;
}
.blue {
  color: #005eff;
  font-size: 20px;
}
.yellow {
  color: #ffc400;
  font-size: 18px;
}
