@import "../../assets/styles/colors";

 .border-left-primary {
     border-left: .25rem solid $primary!important;
 }

 .border-left-success {
    border-left: .25rem solid $success!important;
}

.border-left-info {
    border-left: .25rem solid $info!important;
}

.border-left-warning {
    border-left: .25rem solid $warning!important;
}

.border-left-danger {
    border-left: .25rem solid $danger!important;
}

.text-xs {
    font-size: .7rem;
}
