.verySmallText {
  font-size: 11px;
}
.userMode {
  font-size: 12px;
  font-weight: bold;
  background-color: #ccc;
  padding: 5px;
  border-radius: 5px;
  /* Adjust the value for the desired amount of rounding */
}
.notAllowed{
  color: red;
}
.modeAllowed{
    font-size: 12px;
      font-weight: bold;
      background-color: #000;
      padding: 3px;
      border-radius: 5px;
      color: white
}

.modeNotAllowed {
  font-size: 12px;
  font-weight: bold;
  background-color:red;
  padding: 3px;
  border-radius: 5px;
  color: white
}