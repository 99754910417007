main{
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 1rem;
}

.navbar{
    .userIcon{
        vertical-align: middle;
        margin: auto 0.5rem;
    }
    .logout-button{
        margin-top:0.3rem;
    }
}
