@import "~bootstrap/scss/bootstrap";
@import "assets/styles/colors";

html,
body {
	height: 100%;
}

#root {
	width: 100%;
}

.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
}

a:hover {
	text-decoration: none;
}
.react-datepicker {
	display: inline-flex;
}

.card-columns {
	@include media-breakpoint-only(lg) {
		column-count: 4;
	}
	@include media-breakpoint-only(xl) {
		column-count: 8;
	}
}

.handed {
	cursor: pointer;
}

.custom-tabs {
	margin-bottom: 20px;
}

.custom-tabs .nav-link {
	color: #666; /* Color del texto */
	background-color: #f8f9fa; /* Fondo claro */
	border-radius: 5px; /* Bordes redondeados */
	margin-right: 5px; /* Separación entre pestañas */
	transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-tabs .nav-link.active {
	color: white;
	background-color: #007bff; /* Fondo activo */
}

.custom-tabs .nav-link.active:hover {
	color: white; /* Color del texto al pasar el mouse */
	background-color: #e9ecef; /* Fondo al pasar el mouse */
}

.custom-tabs .nav-link:hover {
	color: black; /* Color del texto al pasar el mouse */
	background-color: #e9ecef; /* Fondo al pasar el mouse */
}
